import React, { useState, useEffect, useCallback } from 'react';
import {
  Button,
  TextField,
  Typography,
  Slider,
  Card,
  CardContent,
  CardHeader,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import emailjs from '@emailjs/browser';

const equipmentPrices = {
  'Monitoreo de Red y Soporte 8x5xNBD': [
    { devices: 1, price: 52.93 },
    { devices: 2, price: 52.66 },
    { devices: 3, price: 52.40 },
    { devices: 4, price: 52.14 },
    { devices: 5, price: 51.88 },
    { devices: 10, price: 51.61 },
    { devices: 20, price: 51.16 },
    { devices: 50, price: 48.94 },
    { devices: 100, price: 48.63 },
    { devices: 500, price: 43.77 },
    { devices: 1000, price: 39.39 },
    { devices: 10000, price: 31.51 },
    { devices: 20000, price: 25.21 },
    { devices: 50000, price: 20.17 },
  ],
  'Monitoreo 24/7 y Soporte Nivel 1, 2 y 3': [
    { devices: 1, price: 61.93 },
    { devices: 2, price: 61.62 },
    { devices: 3, price: 61.31 },
    { devices: 4, price: 61.00 },
    { devices: 5, price: 60.70 },
    { devices: 10, price: 60.39 },
    { devices: 20, price: 59.85 },
    { devices: 50, price: 57.26 },
    { devices: 100, price: 56.90 },
    { devices: 500, price: 51.21 },
    { devices: 1000, price: 46.09 },
    { devices: 10000, price: 36.87 },
    { devices: 20000, price: 29.49 },
    { devices: 50000, price: 23.60 },
  ],
  'Gestionado y Dedicado': [
    { devices: 1, price: 68.81 },
    { devices: 2, price: 68.46 },
    { devices: 3, price: 68.12 },
    { devices: 4, price: 67.78 },
    { devices: 5, price: 67.44 },
    { devices: 10, price: 67.10 },
    { devices: 20, price: 66.50 },
    { devices: 50, price: 63.63 },
    { devices: 100, price: 63.22 },
    { devices: 500, price: 56.90 },
    { devices: 1000, price: 51.21 },
    { devices: 10000, price: 40.96 },
    { devices: 20000, price: 32.77 },
    { devices: 50000, price: 26.22 },
  ],
}

const staffPrices = {
  'Call Center 8x5': 1150.00,
  'NOC Basic 8x5': 2400.00,
  'NOC Medium 8x5': 3250.00,
  'NOC Basic 24x7': 3350.00,
  'NOC Medium 24x7': 6950.00,
  'NOC Intensive 24x7': 17795.00,
  'Support L2 8x5': 5050.00,
  'Support L2 24x7': 5750.00,
  'Support L3': 5300.00,
  'Presales': 1800.00,
}

const serviceDescriptions = {
  'Monitoreo de Red y Soporte 8x5xNBD': 'Supervisión básica de red, soporte horario laboral, respuesta al siguiente día.',
  'Monitoreo 24/7 y Soporte Nivel 1, 2 y 3': 'Monitoreo continuo y soporte especializado en todos los niveles.',
  'Gestionado y Dedicado': 'Gestión total con soporte exclusivo y recursos dedicados.',
  'Call Center 8x5': 'Atención telefónica eficiente en horario laboral estándar.',
  'NOC Basic 8x5': 'Monitoreo básico de red en horario laboral.',
  'NOC Medium 8x5': 'Supervisión de red con atención mejorada en horario laboral.',
  'NOC Basic 24x7': 'Monitoreo básico de red disponible las 24 horas, todos los días.',
  'NOC Medium 24x7': 'Supervisión de red intermedia, servicio ininterrumpido todo el día.',
  'NOC Intensive 24x7': 'Monitoreo intensivo de red, atención especializada todo el año.',
  'Support L2 8x5': 'Soporte especializado de nivel 2 en horario laboral.',
  'Support L2 24x7': 'Soporte avanzado disponible en todo momento para incidentes complejos.',
  'Support L3': 'Soporte avanzado para problemas complejos y críticos.',
  'Presales': 'Asesoría técnica experta para impulsar ventas estratégicas.',
}

const ServiceButton = ({ service, description, isSelected, onClick }) => (
  <Button
    variant={isSelected ? 'contained' : 'outlined'}
    onClick={onClick}
    sx={{
      width: '100%',
      height: 'auto',
      py: 2,
      px: 3,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      textAlign: 'left',
      borderColor: '#E31E22',
      color: 'white',
      backgroundColor: isSelected ? '#E31E22' : 'transparent',
      '&:hover': {
        backgroundColor: isSelected ? '#C1272D' : 'rgba(227, 30, 34, 0.1)',
      },
    }}
  >
    <Typography variant="subtitle1" sx={{ mb: 1, textTransform: 'uppercase' }}>
      {service}
    </Typography>
    <Typography variant="body2" sx={{ fontSize: '0.85rem', color: '#fff', textTransform: 'none' }}>
      {description}
    </Typography>
  </Button>
);

export default function ServiceCalculator() {
  const [calculatorType, setCalculatorType] = useState(null);
  const [step, setStep] = useState(0)
  const [devices, setDevices] = useState(1);  // Mueve esta declaración aquí
  const [selectedServices, setSelectedServices] = useState({
    equipmentService: 'Monitoreo de Red y Soporte 8x5xNBD',
    flowService: '',
    additionalServices: [],
  });  
  const [total, setTotal] = useState(0);
  const [formData, setFormData] = useState({
    name: '',
    company: '',
    phone: '',
    email: '',
  });

  const equipmentServices = Object.keys(equipmentPrices)
  const flowServices = Object.keys(staffPrices).filter(service => service.startsWith('NOC') || service.startsWith('Support'))
  const additionalServices = ['Call Center 8x5', 'Presales']

  useEffect(() => {
    console.log('Devices:', devices);  // Verifica que siempre sea un número
    console.log('Selected Services:', selectedServices);  // Verifica que siempre sea un objeto
    console.log('Additional Services:', selectedServices.additionalServices);  // Verifica que siempre sea un array

    // Recalcular el total al renderizar la pantalla de selección de servicios por dispositivos
    if (step === 1 && calculatorType === 'dispositivo') {
        calculateTotal(); 
    } else {
        calculateTotal();  // También recalcula normalmente para otros pasos o cambios de dispositivos/servicios
    }

}, [devices, selectedServices, step, calculatorType]);  // Ahora el efecto también depende de `step` y `calculatorType`

const calculateTotal = useCallback(() => {
  let totalPrice = 0;

  if (calculatorType === 'dispositivo') {
      const priceList = equipmentPrices[selectedServices.equipmentService];
      for (let i = priceList.length - 1; i >= 0; i--) {
          if (devices >= priceList[i].devices) {
              totalPrice = priceList[i].price * devices;
              break;
          }
      }
  } else if (calculatorType === 'staff') {
      if (selectedServices.flowService) {
          totalPrice += staffPrices[selectedServices.flowService];
      }
  }

  // Asegúrate de sumar correctamente los servicios adicionales
  if (selectedServices.additionalServices.length > 0) {
      totalPrice += selectedServices.additionalServices.reduce(
          (sum, service) => sum + staffPrices[service],
          0
      );
  }

  setTotal(totalPrice);
}, [devices, selectedServices, calculatorType]);

const handleServiceSelection = (category, service) => {
    setSelectedServices(prev => {
        if (category === 'equipmentService') {
            return { ...prev, [category]: service };
        } else if (category === 'flowService') {
            return { ...prev, [category]: service };
        } else if (category === 'additionalServices') {
            const updatedServices = prev[category].includes(service)
                ? prev[category].filter(s => s !== service)
                : [...prev[category], service];
            return { ...prev, [category]: updatedServices };
        }
        return prev;
    });
};
        
  const handleDeviceChange = (e) => {
    const value = parseInt(e.target.value, 10)
    if (!isNaN(value) && value >= 1 && value <= 50000) {
      setDevices(value)
    }
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target
    setFormData(prev => ({ ...prev, [name]: value }))
  }

  const nextStep = () => {
    if (step === 1 && calculatorType === 'dispositivo' && !selectedServices.equipmentService) {
      alert('Por favor, selecciona un servicio por cantidad de dispositivos antes de continuar.');
      return;
    }

    if (step === 1 && calculatorType === 'staff' && !selectedServices.flowService) {
      alert('Por favor, selecciona un servicio por flujo de atención antes de continuar.');
      return;
    }

    // Si la validación pasa, avanza al siguiente paso
    setStep(step + 1);
}; 

  const prevStep = () => {
    if (step > 0) setStep(step - 1)
  }

  const resetCalculator = () => {
    setCalculatorType(null)
    setStep(0)
    setDevices(1)
    setSelectedServices({
      equipmentService: 'Monitoreo de Red y Soporte 8x5xNBD',
      flowService: '',
      additionalServices: [],
    })
    setTotal(0)
    setFormData({
      name: '',
      company: '',
      phone: '',
      email: '',
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault();
  
    // Validación de campos obligatorios
    if (!formData.name || !formData.email || (!selectedServices.equipmentService && !selectedServices.flowService)) {
      alert('Por favor, completa todos los campos obligatorios y selecciona un servicio.');
      return; // Detenemos el envío si no se han llenado todos los campos obligatorios
    }
  
    // Preparar los datos para enviar a EmailJS
    const templateParams = {
      name: formData.name || 'No proporcionado',
      company: formData.company || 'No proporcionado',
      phone: formData.phone || 'No proporcionado',
      email: formData.email || 'No proporcionado',
      equipmentService: calculatorType === 'dispositivo' ? selectedServices.equipmentService : 'N/A',
      devices: calculatorType === 'dispositivo' ? devices : 'N/A',
      flowService: calculatorType === 'staff' ? selectedServices.flowService : 'N/A',
      additionalServices: selectedServices.additionalServices.length > 0 ? selectedServices.additionalServices.join(', ') : 'N/A',
      total: total.toFixed(2),
    };
  
    // Enviar el correo electrónico usando EmailJS
    emailjs
      .send('service_5hy01um', 'template_nzsif06', templateParams, 'q2k7OEDWeKdc60NPq')
      .then(
        (response) => {
          console.log('Correo enviado con éxito', response.status, response.text);
          alert('Solicitud enviada correctamente.');
          
          // Mover a la pantalla final con la información capturada
          setStep(5); 
        },
        (err) => {
          console.error('Error al enviar el correo:', err);
          alert('Hubo un error al enviar la solicitud. Por favor, inténtalo de nuevo.');
        }
      );
};



  const formatPrice = (price) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(price);
  };  

  const renderStep = () => {
    switch (step) {
      case 0:
        return (
          <div className="bg-zinc-900 rounded-xl p-6 shadow-lg">
            <h2 className="text-xl font-semibold mb-4 text-center">Cotizar por:</h2>
            <div className="flex justify-center gap-4">
              <Button
                onClick={() => {
                  setCalculatorType('dispositivo');
                  nextStep();
                }}
                style={{
                  width: '50%', // Ajuste para que ambos botones tengan el mismo ancho
                  backgroundColor: calculatorType === 'dispositivo' ? '#E31E22' : 'transparent',
                  color: '#FFF',
                  border: '2px solid #E31E22',
                  borderRadius: '20px',
                  fontSize: '18px',
                  padding: '15px 40px',
                  textTransform: 'none',
                  fontWeight: 'bold',
                }}
              >
                Dispositivo
              </Button>
              <Button
                onClick={() => {
                  setCalculatorType('staff');
                  nextStep();
                }}
                style={{
                  width: '50%', // Ajuste para que ambos botones tengan el mismo ancho
                  backgroundColor: calculatorType === 'staff' ? '#E31E22' : 'transparent',
                  color: '#FFF',
                  border: '2px solid #E31E22',
                  borderRadius: '20px',
                  fontSize: '18px',
                  padding: '15px 40px',
                  textTransform: 'none',
                  fontWeight: 'bold',
                }}
              >
                Staff
              </Button>
            </div>
          </div>
        );

  
        case 1:
          if (calculatorType === 'dispositivo') {
            return (
              <div className="bg-zinc-900 rounded-xl p-6 shadow-lg">
                <h2 className="text-xl font-semibold mb-4">
                  Servicios por cantidad de dispositivos
                </h2>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3">
                  {equipmentServices.map((service) => (
                    <ServiceButton
                      key={service}
                      service={service}
                      description={serviceDescriptions[service]}
                      isSelected={selectedServices.equipmentService === service}
                      onClick={() => handleServiceSelection('equipmentService', service)}
                    />
                  ))}
                </div>
              </div>
            );
          }
          else if (calculatorType === 'staff') {
          return (
            <div className="bg-zinc-900 rounded-xl p-6 shadow-lg">
              <h2 className="text-xl font-semibold mb-4">
                Servicios por flujo de atención
              </h2>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3">
                {flowServices.map((service) => (
                  <ServiceButton
                    key={service}
                    service={service}
                    description={serviceDescriptions[service]}
                    isSelected={selectedServices.flowService === service}
                    onClick={() => handleServiceSelection('flowService', service)}
                  />
                ))}
              </div>
            </div>
          )
        }
        return null
      case 2:
        if (calculatorType === 'dispositivo') {
          return (
            <div className="bg-zinc-900 rounded-xl p-6 shadow-lg">
              <h2 className="text-xl font-semibold mb-4">
                Número de Dispositivos
              </h2>
              <div className="space-y-4">
                <Slider
                  value={devices}
                  onChange={(e, newValue) => setDevices(Number(newValue))}
                  max={50000}
                  min={1}
                  step={1}
                  sx={{
                    color: '#DC2626',
                    '& .MuiSlider-thumb': {
                      width: 20,
                      height: 20,
                      backgroundColor: '#FFFFFF',
                      border: '2px solid #DC2626',
                    },
                    '& .MuiSlider-track': {
                      backgroundColor: '#DC2626',
                    },
                    '& .MuiSlider-rail': {
                      backgroundColor: '#F5F5F5',
                    },
                  }}
                />
                <div className="flex justify-between items-center">
                <Typography htmlFor="deviceInput">Dispositivos</Typography>
                <TextField
                  id="deviceInput"
                  type="number"
                  value={devices}
                  onChange={handleDeviceChange}
                  inputProps={{ min: 1, max: 50000 }}
                  sx={{
                    width: '6rem',
                    backgroundColor: '#27272A',
                    input: { color: 'white' },
                  }}
                />
                </div>
              </div>
            </div>
          )
        } else if (calculatorType === 'staff') {
          return (
            <div className="bg-zinc-900 rounded-xl p-6 shadow-lg">
              <h2 className="text-xl font-semibold mb-4">
                Servicios adicionales
              </h2>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
                {additionalServices.map((service) => (
                  <ServiceButton
                    key={service}
                    service={service}
                    description={serviceDescriptions[service]}
                    isSelected={selectedServices.additionalServices.includes(service)}
                    onClick={() => handleServiceSelection('additionalServices', service)}
                  />
                ))}
              </div>
            </div>
          )
        }
        return null
      case 3:
        if (calculatorType === 'dispositivo') {
          return (
            <div className="bg-zinc-900 rounded-xl p-6 shadow-lg">
              <h2 className="text-xl font-semibold mb-4">
                Servicios adicionales
              </h2>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
                {additionalServices.map((service) => (
                  <ServiceButton
                    key={service}
                    service={service}
                    description={serviceDescriptions[service]}
                    isSelected={selectedServices.additionalServices.includes(service)}
                    onClick={() => handleServiceSelection('additionalServices', service)}
                  />
                ))}
              </div>
            </div>
          )
        }
        // Fall through for both calculatorTypes
        case 4: // Formulario de cotización
        return (
          <div className="bg-zinc-900 rounded-xl p-6 shadow-lg max-w-xl mx-auto">
            <h2 className="text-xl font-semibold mb-4">
              Solicitar Cotización
            </h2>
            <form onSubmit={handleSubmit} className="space-y-4" style={{ maxWidth: '50%' }}>
              <div className="space-y-2">
                <Typography htmlFor="deviceInput">Nombre</Typography>
                <TextField
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleInputChange}
                  placeholder="Tu nombre"
                  required
                  sx={{
                    backgroundColor: '#27272A',
                    input: { color: 'white' },
                    width: '100%'  // Aseguramos que ocupe el ancho completo
                  }}
                />
              </div>
              <div className="space-y-2">
                <Typography htmlFor="deviceInput">Empresa</Typography>
                <TextField
                  id="company"
                  name="company"
                  value={formData.company}
                  onChange={handleInputChange}
                  placeholder="Nombre de la empresa"
                  required
                  sx={{
                    backgroundColor: '#27272A',
                    input: { color: 'white' },
                    width: '100%'
                  }}
                />
              </div>
              <div className="space-y-2">
                <Typography htmlFor="deviceInput">Teléfono</Typography>
                <TextField
                  id="phone"
                  name="phone"
                  value={formData.phone}
                  onChange={handleInputChange}
                  type="tel"
                  placeholder="Tu número de teléfono"
                  required
                  sx={{
                    backgroundColor: '#27272A',
                    input: { color: 'white' },
                    width: '100%'
                  }}
                />
              </div>
              <div className="space-y-2">
                <Typography htmlFor="deviceInput">Correo Electrónico</Typography>
                <TextField
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleInputChange}
                  type="email"
                  placeholder="tu@email.com"
                  required
                  sx={{
                    backgroundColor: '#27272A',
                    input: { color: 'white' },
                    width: '100%'
                  }}
                />
              </div>
              {/* Botón de enviar */}
              <Button
                type="submit"
                variant="contained"
                sx={{
                  backgroundColor: '#E31E22',
                  color: 'white',
                  fontWeight: 'bold',
                  width: '100%', // Ocupar el ancho completo del formulario
                  padding: '15px',
                  '&:hover': {
                    backgroundColor: '#C1272D',
                  },
                }}
              >
                Enviar Solicitud
              </Button>
            </form>
          </div>
        )
        
        case 5:
    return (
        <div className="bg-gradient-to-br from-zinc-900 to-zinc-800 rounded-xl p-8 shadow-lg space-y-8">
          <div className="text-center space-y-4">
            <h2 className="text-3xl font-bold text-white">¡Gracias por tu solicitud!</h2>
            <p className="text-xl text-gray-300">Tu cotización ha sido enviada con éxito.</p>
          </div>

          {/* Detalles de la Cotización */}
          <Card sx={{ backgroundColor: '#353538', border: 'none' }}>
            <CardHeader>
              <Typography variant="h6" sx={{ color: '#E31E22', fontWeight: 'bold' }}>
                Detalles de la cotización
              </Typography>
            </CardHeader>
            <CardContent>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ color: '#FFF', borderBottom: '1px solid #fff' }}>Concepto</TableCell>
                    <TableCell sx={{ color: '#FFF', borderBottom: '1px solid #fff' }}>Detalle</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell sx={{ color: '#FFF', borderBottom: '1px solid #fff' }}>Tipo de cotización</TableCell>
                    <TableCell sx={{ color: '#FFF', borderBottom: '1px solid #fff' }}>{calculatorType === 'dispositivo' ? 'Por dispositivo' : 'Por staff'}</TableCell>
                  </TableRow>

                  {/* Mostrar Servicio seleccionado según tipo */}
                  {calculatorType === 'dispositivo' && (
                    <>
                      <TableRow>
                        <TableCell sx={{ color: '#FFF', borderBottom: '1px solid #fff' }}>Servicio seleccionado</TableCell>
                        <TableCell sx={{ color: '#FFF', borderBottom: '1px solid #fff' }}>{selectedServices.equipmentService}</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell sx={{ color: '#FFF', borderBottom: '1px solid #fff' }}>Número de dispositivos</TableCell>
                        <TableCell sx={{ color: '#FFF', borderBottom: '1px solid #fff' }}>{devices}</TableCell>
                      </TableRow>
                    </>
                  )}
                  {calculatorType === 'staff' && (
                    <TableRow>
                      <TableCell sx={{ color: '#FFF', borderBottom: '1px solid #fff' }}>Servicio seleccionado</TableCell>
                      <TableCell sx={{ color: '#FFF', borderBottom: '1px solid #fff' }}>{selectedServices.flowService}</TableCell>
                    </TableRow>
                  )}

                  {/* Mostrar Servicios adicionales */}
                  {selectedServices.additionalServices.length > 0 && (
                    <TableRow>
                      <TableCell sx={{ color: '#FFF', borderBottom: '1px solid #fff' }}>Servicios adicionales</TableCell>
                      <TableCell sx={{ color: '#FFF', borderBottom: '1px solid #fff' }}>
                        <ul className="list-disc list-inside">
                          {selectedServices.additionalServices.map(service => (
                            <li key={service}>{service}</li>
                          ))}
                        </ul>
                      </TableCell>
                    </TableRow>
                  )}
                  <TableRow>
                    <TableCell sx={{ color: '#FFF', borderBottom: 'none' }}>Total estimado</TableCell>
                    <TableCell sx={{ color: '#FFF', fontWeight: 'bold', fontSize: '1.5rem', borderBottom: 'none' }}>{formatPrice(total)}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </CardContent>
          </Card>

          {/* Información de contacto */}
          <Card sx={{ backgroundColor: '#353538', border: 'none' }}>
            <CardHeader>
              <Typography variant="h6" sx={{ color: '#E31E22', fontWeight: 'bold' }}>
                Información de contacto
              </Typography>
            </CardHeader>
            <CardContent>
              <Table>
                <TableBody>
                <TableRow>
                  <TableCell sx={{ color: '#FFF', borderBottom: '1px solid #fff' }}>Nombre:</TableCell>
                  <TableCell sx={{ color: '#FFF', borderBottom: '1px solid #fff' }}>{formData.name || 'No proporcionado'}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ color: '#FFF', borderBottom: '1px solid #fff' }}>Empresa:</TableCell>
                  <TableCell sx={{ color: '#FFF', borderBottom: '1px solid #fff' }}>{formData.company || 'No proporcionado'}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ color: '#FFF', borderBottom: '1px solid #fff' }}>Teléfono:</TableCell>
                  <TableCell sx={{ color: '#FFF', borderBottom: '1px solid #fff' }}>{formData.phone || 'No proporcionado'}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell sx={{ color: '#FFF', borderBottom: 'none' }}>Correo Electrónico:</TableCell>
                  <TableCell sx={{ color: '#FFF', borderBottom: 'none' }}>{formData.email || 'No proporcionado'}</TableCell>
                </TableRow>
                </TableBody>
              </Table>
            </CardContent>
          </Card>

          {/* Botón para nueva cotización */}
          <div className="text-center space-y-6">
            <p className="text-gray-300">
              Nos pondremos en contacto contigo pronto para discutir los detalles de tu cotización. Revisaremos tu solicitud cuidadosamente para ajustar el monto según tus necesidades y ofrecerte una opción que se adecúe a tu presupuesto.
            </p>
            <Button 
              onClick={resetCalculator} 
              sx={{
                backgroundColor: '#E31E22', 
                color: 'white', 
                borderRadius: '20px', 
                padding: '10px 40px',
                fontWeight: 'bold',
                '&:hover': {
                  backgroundColor: '#B91C1C',
                }
              }}
            >
              Comenzar nueva cotización
            </Button>
          </div>
        </div>
      );



      default:
        return null
    }
  }

  const totalSteps = calculatorType === 'dispositivo' ? 6 : 5

  return (
    <div className="min-h-screen bg-black text-white">
      <div className="sticky top-0 z-10 bg-black bg-opacity-90 backdrop-blur-sm border-b border-red-600/20 p-4 flex justify-between items-center">
        <h1 className="text-2xl font-bold">
          Calculadora <span className="text-red-600">Reddot</span>
        </h1>
        <div className="flex items-center gap-4">
          <div className="flex items-center gap-2">
            <span className="text-lg font-medium text-white">Mensual:</span>
            <span className="text-2xl font-bold text-white">{formatPrice(total)}</span>
          </div>
        </div>
      </div>
      
      <div className="max-w-4xl mx-auto p-6 space-y-8">
        {step > 0 && step < totalSteps - 1 && (
          <div className="text-center text-lg font-semibold">
            Paso {step} de {totalSteps - 2}
          </div>
        )}
        <div className="space-y-6">
          {renderStep()}
        </div>

        {step > 0 && step < totalSteps - 1 && step !== (totalSteps - 2) && (
          <div className="flex justify-between mt-8">
            <Button
              onClick={step === 1 ? resetCalculator : prevStep}
              variant="contained"
              sx={{
                backgroundColor: '#DC2626',
                color: 'white',
                '&:hover': { backgroundColor: '#B91C1C' },
              }}
            >
              {step === 1 ? 'Inicio' : 'Atrás'}
            </Button>
            <Button
              onClick={nextStep}
              variant="contained"
              sx={{
                backgroundColor: '#DC2626',
                color: 'white',
                '&:hover': { backgroundColor: '#B91C1C' },
              }}
            >
              Siguiente
            </Button>
          </div>
        )}

      </div>
    </div>
  )
}